import Head from 'next/head'
import { Container } from 'react-bootstrap'
import Products from './products'

export default function Home() {
  return (
    <>
      <Container>
        <Head>
          <title>Wildcloud Console</title>
          <link rel="icon" href="/favicon.ico" />
        </Head>
        <Products />
      </Container>
    </>
  )
}
